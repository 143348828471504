export const blogList = [
  {
    id: 1,
    title: "sinkndink calligraphypractice vintageglassware",
    category: "sinkndink",
    subCategory: ["frontend", "ui/ux", "design"],
    description: "",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "MAY 3, 2022",
    cover: "/assets/images/1.png",
    postLink: "https://www.instagram.com/p/CdGixqzj5z0/",
  },
  {
    id: 2,
    title: "What a beautiful touch.🥰",
    category: "Personalised Gift",
    subCategory: ["vacation", "holidays", "sight seeing"],
    description:
      "I am very happy that I got a chance to make these ❤ hand engraved on perfume bottle on occasion of Mother's day 😍💖...",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "APRIL 27, 2022",
    cover: "/assets/images/2.png",
    postLink: "https://www.instagram.com/p/Cc3A3M3jm2o/",
  },
  {
    id: 3,
    title: "Good Friday 🤎💫",
    category: "Brush lettering",
    subCategory: ["e-commerce store", "clothing", "shopping store"],
    description: " ",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "APRIL 15, 2022",
    cover: "/assets/images/3.png",
    postLink: "https://www.instagram.com/p/CcYNhJ5DYZZ/",
  },
  {
    id: 4,
    title: "Back to engraving and I'm loving it",
    category: "Calligritype",
    subCategory: ["adrenaline", "stay-fit", "lifestyle"],
    description:
      "There's personalized wedding gift.creat the perfect treasured keepsake.and make memory last forever 💞🥰",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "APRIL 12, 2022",
    cover: "/assets/images/4.png",
    postLink: "https://www.instagram.com/p/CcQrW1cDKpD/",
  },
  {
    id: 5,
    title: "Personalized file..🌟",
    category: "Name Writing",
    subCategory: ["bbq", "food", "lifestyle"],
    description: "",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "MARCH 16, 2022",
    cover: "/assets/images/5.png",
    postLink: "https://www.instagram.com/p/CbJokK-jR8o/",
  },
  {
    id: 6,
    title: "Birthday tag💫😍🥳",
    category: "Calligraphy Master",
    subCategory: ["beaches", "sea", "holidays"],
    description: "",
    authorName: "Supriya Ghosh",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "MARCH 12, 2022",
    cover: "/assets/images/6.png",
    postLink: "https://www.instagram.com/p/CbAHXW1jMzt/",
  },
];
